import { createSlice } from "@reduxjs/toolkit";
import { setSoftone } from "./services";
const initialState = JSON.parse(localStorage.getItem("questionnaires")) || null;

const questionnairesSlice = createSlice({
  name: "questionnaires",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSoftone, (state, action) => {
      // Create an object where keys are the questionnaire IDs
      const val = action.payload.questionnaires.reduce((acc, current) => {
        const questionnaireId = Number(current.questionnaire);

        // Find or create the questionnaire in acc using its ID as the key
        if (!acc[questionnaireId]) {
          acc[questionnaireId] = {
            questionnaire: questionnaireId,
            name: current.questionnaireName,
            questions: [],
          };
        }

        const questionnaire = acc[questionnaireId];

        // Find if the question already exists in the current questionnaire
        let question = questionnaire.questions.find((q) => q.code === Number(current.question));

        if (!question) {
          // If not, add the new question

          question = {
            code: Number(current.question),
            name: current.questionName,
            isRequired: current.isRequired === "1",
            type: current.questionType,
            properties: JSON.parse(current.questionProperties ?? "{}"),
            answers: [],
          };
          questionnaire.questions.push(question);
        }

        // Push the answer to the current question
        question.answers.push({
          code: Number(current.answer),
          name: current.answerName,
        });

        return acc;
      }, {}); // Initialize with an empty object to collect all questionnaires by ID

      localStorage.setItem("questionnaires", JSON.stringify(val));
      return val;
    });
  },
});

export default questionnairesSlice.reducer;
