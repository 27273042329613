import React from "react";
import { Button, Modal } from "react-bootstrap";
import { HiCheck, HiX } from "react-icons/hi";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading, setSignature } from "../redux/slices/ui";
import axios from "axios";

const ModalSignature = ({ f }) => {
  const dispatch = useDispatch();
  const trdr = useSelector((state) => state?.trdr?.trdr);
  const show = useSelector((state) => state.ui.signature);
  const clientID = useSelector((state) => state.auth.clientID);

  const [isSigned, setIsSigned] = React.useState(false);
  const canvasRef = React.useRef(null);

  const handleAccept = async () => {
    dispatch(setLoading(true));
    const base64Image = await fetch(canvasRef.current.getTrimmedCanvas().toDataURL("image/png"));
    const blob = await base64Image.blob();

    const formData = new FormData();
    formData.append("signature", blob, "signature.png");
    formData.append("trdr", trdr);
    formData.append("clientID", clientID);
    formData.append("appID", process.env.REACT_APP_S1_APPID);

    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/setCustomerSignature`, formData);

    if (response.data.success) {
      dispatch(setSignature(false));
      f(response.data.xdoc);
    } else {
      dispatch(setLoading(false));
      dispatch(setError(response.data.error));
    }
  };

  const handleClear = () => {
    canvasRef.current.clear();
    setIsSigned(false);
  };

  const handleEnd = () => {
    setIsSigned(!canvasRef.current.isEmpty());
  };

  return (
    <Modal
      show={show}
      fullscreen
      className="d-flex align-items-end"
      dialogClassName="p-4"
      contentClassName="rounded overflow-hidden shadow"
      onHide={() => dispatch(setSignature(false))}
    >
      <Modal.Header closeButton />
      <Modal.Body className="p-1 overflow-hidden">
        <SignatureCanvas
          ref={canvasRef}
          canvasProps={{ className: "h-100 w-100 sigCanvas" }}
          onEnd={handleEnd} // Check if the user finished drawing
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="outline-secondary" onClick={handleClear}>
          <HiX size="1.5rem" color="var(--bs-danger)" className="me-2" />
          <span>Καθαρισμός</span>
        </Button>
        <Button variant="outline-secondary" onClick={handleAccept} disabled={!isSigned}>
          <HiCheck size="1.5rem" color="var(--bs-success)" className="me-2" />
          <span>Αποθήκευση</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSignature;
