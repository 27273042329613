// src/components/Loader.js
import React from "react";

const Loader = () => {
  return (
    <div className="loader-container">
      <img src="/logo-antiagers.svg" alt="Loading..." className="loader-logo" />
    </div>
  );
};

export default Loader;
