import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setPreview } from "../redux/slices/ui";

const ModalPreview = () => {
  const dispatch = useDispatch();
  const preview = useSelector((state) => state.ui.preview);
  const sn = useSelector((state) => state.login?.sn);
  return (
    <Modal
      show={preview.show}
      fullscreen
      className="d-flex align-items-end"
      dialogClassName="p-3"
      contentClassName="rounded overflow-hidden shadow"
      onHide={() => dispatch(setPreview(false))}
    >
      <Modal.Header closeButton />
      <Modal.Body className="p-0 overflow-hidden">
        {preview && (
          <iframe
            title="preview"
            style={{ height: "100%", width: "100%" }}
            src={`https://${sn}.oncloud.gr/s1services?filename=${preview.url}`}
          ></iframe>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalPreview;
