import { configureStore } from "@reduxjs/toolkit";
// const reduxLogger = require("redux-logger");
import uiReducer from "./slices/ui";
import loginReducer from "./slices/login";
import authReducer from "./slices/auth";
import servicesReducer from "./slices/services";
import questionairesReducer from "./slices/questionnaiers";
import gdprReducer from "./slices/gdpr";
import trdrReducer from "./slices/trdr";
import salesChanelsReducer from "./slices/salesChanels";

// const logger = reduxLogger.createLogger();

const store = configureStore({
  reducer: {
    ui: uiReducer,
    login: loginReducer,
    auth: authReducer,
    salesChanels: salesChanelsReducer,
    services: servicesReducer,
    questionnaires: questionairesReducer,
    gdpr: gdprReducer,
    trdr: trdrReducer,
  },
  //   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
