import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: false,
  signature: false,
  preview: {
    show: false,
    url: "",
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSignature: (state, action) => {
      state.signature = action.payload;
    },
    setPreview: (state, action) => {
      state.preview = action.payload;
    },
  },
});

export default uiSlice.reducer;
export const { setLoading, setError, setSignature, setPreview } = uiSlice.actions;
