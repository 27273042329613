import axios from "axios";
import store from "../redux/store";

const getFromJS = async (f, data) => {
  const state = store.getState();
  const clientID = state.auth.clientID;

  return await axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/s1services`, {
      ...data,
      service: "getfromjs",
      clientID,
      function: f,
      appID: process.env.REACT_APP_S1_APPID,
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default getFromJS;
