import React from "react";
import { Alert, Button, Card, FloatingLabel, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import login from "../services/login";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../redux/slices/ui";
import { setLogin } from "../redux/slices/login";
import authenticate from "../services/authenticate";
import { setAuth } from "../redux/slices/auth";

const Login = () => {
  const dispatch = useDispatch();

  const clientID = useSelector((state) => state?.auth?.clientID);

  const [userName, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [error, setError] = React.useState("");

  const navigate = useNavigate();

  React.useEffect(() => {
    if (clientID) {
      navigate("/"); // Redirect to app if logged in
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();
    dispatch(setLoading(true));

    const response = await login({
      userName,
      password,
    });

    console.log(response);

    if (response.success) {
      dispatch(setLogin({ ...response, password }));
      const auth = await authenticate({
        clientID: response.clientID,
        company: response.objs[0].COMPANY,
        branch: response.objs[0].BRANCH,
        refid: response.objs[0].REFID,
      });
      if (auth.success) {
        dispatch(setAuth(auth));
        dispatch(setLoading(false));
        navigate("/");
      } else {
        dispatch(setLoading(false));
        setError(response.error);
      }

      //   navigate("/app");
    } else {
      dispatch(setLoading(false));
      setError(response.error);
    }
  };
  return (
    <>
      <Card.Body className="overflow-auto">
        <Form autoComplete="off">
          <FloatingLabel controlId="Username" label="Username" className="mb-2">
            <Form.Control
              type="username"
              placeholder="Username"
              value={userName}
              onChange={(e) => setUsername(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="password" label="Password">
            <Form.Control
              type="password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FloatingLabel>
          {error && (
            <Alert variant="danger" className="mt-2 mb-0">
              {error}
            </Alert>
          )}
        </Form>
      </Card.Body>
      <Card.Footer>
        <Button onClick={handleSubmit} variant="primary" className="w-100" type="submit">
          Σύνδεση
        </Button>
      </Card.Footer>
    </>
  );
};

export default Login;
