import React from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const ModalPass = ({ show, setShow, setcConfirmPassl }) => {
  const [pass, setPass] = React.useState("");
  const login = useSelector((state) => state.login);
  const ref = React.useRef(null);
  const formRef = React.useRef(null);

  React.useEffect(() => {
    if (show && ref.current) {
      setTimeout(() => {
        ref.current.focus();
        ref.current.click();
      }, 200);
    } else {
      setPass("");
    }
  }, [show]); // This effect runs when `show` changes

  const handleAccept = (e) => {
    e.preventDefault();
    if (pass === login.password) setcConfirmPassl(true);
    setShow(false);
  };

  return (
    <Modal animation={false} show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <form ref={formRef} onSubmit={handleAccept} autoComplete="off">
          <FloatingLabel controlId="sssformName" label="Κωδικός">
            <Form.Control
              type="password"
              ref={ref} // Attach the ref here
              name="sssformName"
              autoComplete="new-password"
              placeholder="Κωδικός"
              autoFocus
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </FloatingLabel>
        </form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={() => setShow(false)} variant="secojndary">
          Ακύρωση
        </Button>
        <Button onClick={handleAccept} type="submit" variant="success">
          Επόμενο
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPass;
