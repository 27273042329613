import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem("services")) || {
  services: [],
  categories: [],
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setSoftone: (state, action) => {
      const categories = Array.from(
        new Map(
          action.payload.services.map((item) => [
            item.categoryName,
            { name: item.categoryName, code: item.mtrcategory },
          ])
        ).values()
      );
      state.categories = categories;
      state.services = action.payload.services;
      localStorage.setItem("services", JSON.stringify(state));
    },
  },
});

export default servicesSlice.reducer;
export const { setSoftone } = servicesSlice.actions;
