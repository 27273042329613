import React from "react";
import { Button, Card, FloatingLabel, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import getFromJS from "../services/getFromJS";
import { setLoading, setSignature } from "../redux/slices/ui";
import ModalSignature from "../components/ModalSignature";
import { TbSignature } from "react-icons/tb";
import SingleChoice from "../components/questionnaires/SingleChoice";
import Type5 from "../components/questionnaires/Type5";

const Questionnaire = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState([]);
  const { trdr, questionnaire } = useParams();
  const qq = useSelector((state) => state.questionnaires?.[questionnaire]) ?? {};
  const ref = React.useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setSignature(true));
  };

  const handleSave = async (xdoc) => {
    dispatch(setLoading(true));
    const response = await getFromJS("setQuestionnaire", {
      trdr,
      questionnaire,
      answers: values,
      xdoc,
    });

    navigate(`/customer/${trdr}`);
    dispatch(setLoading(false));
  };
  return (
    <>
      <Card.Body style={{ overflowY: "auto" }}>
        <Form ref={ref} onSubmit={handleSubmit}>
          {qq?.questions.map((q) => {
            switch (q.type) {
              case 5:
              case "5":
                return <Type5 key={q.code} question={q} values={values} setValues={setValues} />;

              case 7:
              case "7":
                return <SingleChoice key={q.code} question={q} values={values} setValues={setValues} />;
              default:
                return <SingleChoice key={q.code} question={q} values={values} setValues={setValues} />;
            }
          })}
        </Form>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <Button onClick={() => navigate(`/customer/${trdr}`)} variant="secoendary">
          Πίσω
        </Button>
        <Button
          onClick={() => ref.current.requestSubmit()}
          type="submit"
          variant="success"
          disabled={values.length != qq?.questions.length}
        >
          <TbSignature size="1.5rem" className="me-2" />
          Υπογραφή
        </Button>
      </Card.Footer>
      <ModalSignature f={handleSave} />
    </>
  );
};

export default Questionnaire;
