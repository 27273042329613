import axios from "axios";
import store from "../redux/store";

const printDoc = async ({ object, template, key }) => {
  const state = store.getState();
  const clientID = state.auth.clientID;

  return await axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/s1services`, {
      service: "printDoc",
      clientID,
      object,
      format: "PDF",
      template,
      key,
      appID: process.env.REACT_APP_S1_APPID,
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default printDoc;
