import React from "react";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import setData from "../services/setData";
import { setLoading } from "../redux/slices/ui";
import { FaRegFilePdf } from "react-icons/fa";

const Interest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trdr } = useParams();
  const categories = useSelector((state) => state.services?.categories);
  const services = useSelector((state) => state.services?.services);
  const [selectedCode, setSelecteCodes] = React.useState([]);

  const handleSave = async () => {
    dispatch(setLoading(true));
    const response = await setData({
      key: "",
      object: "SOACTION",
      FORM: "Ενδιαφέρον",
      data: {
        SOACTION: [{ series: 1050, trdr }],
        CCCSOACTIONINTEREST: selectedCode.map((mtrl) => ({ INTCODE: mtrl })),
      },
    });
    navigate(`/customer/${trdr}`);
    dispatch(setLoading(false));
  };

  const handleCheck = (value, mtrl) => {
    const sm = [...selectedCode];
    if (value) {
      sm.push(mtrl);
    } else {
      sm.splice(sm.indexOf(mtrl), 1);
    }
    setSelecteCodes(sm);
  };
  return (
    <>
      <Card.Body style={{ overflowY: "auto" }}>
        {categories.map((category) => {
          const data = services.filter((item) => item.mtrcategory == category.code);
          return (
            <Card key={category.code} className="mb-4">
              <Card.Header className="category-title">{category.name}</Card.Header>
              <Card.Body className="p-0">
                <ListGroup className="border-0">
                  {data.map((srv) => (
                    <ListGroup.Item
                      key={srv.mtrl}
                      style={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderBottom:
                          "var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)!important",
                      }}
                      className={`d-flex flex-row align-items-center justify-content-between border-0${
                        selectedCode.indexOf(srv.mtrl) > -1 ? " selected" : ""
                      }`}
                    >
                      <div className="text-truncate">{srv.name}</div>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ minWidth: "2rem", minHeight: "2rem" }}
                          value={selectedCode.indexOf(srv.mtrl) > -1}
                          onChange={(e) => handleCheck(e.target.checked, srv.mtrl)}
                        />
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          );
        })}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <Button onClick={() => navigate(`/customer/${trdr}`)} variant="secoendary">
          Πίσω
        </Button>
        <Button onClick={handleSave} variant="success" disabled={selectedCode.length == 0}>
          Αποθήκευση
        </Button>
      </Card.Footer>
    </>
  );
};

export default Interest;
