import React from "react";
import { Button, Card, FloatingLabel, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoading } from "../redux/slices/ui";
import getFromJS from "../services/getFromJS";
import { useNavigate } from "react-router-dom";
import { setTrdr } from "../redux/slices/trdr";

const NewCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formRef = React.useRef(null);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [zip, setZip] = React.useState("");
  const [city, setCity] = React.useState("");
  const [sex, setSex] = React.useState("");
  const [salesChanel, setSalesChanel] = React.useState("");
  const [validated, setValidated] = React.useState(false);

  const salesChanels = useSelector((state) => state.salesChanels);

  const fields = [
    {
      controlId: "firstName",
      value: firstName,
      placeholder: "Όνομα",
      type: "text",
      required: true,
      onChange: (e) => setFirstName(e.target.value),
    },
    {
      controlId: "lastName",
      value: lastName,
      placeholder: "Επώνυμο",
      type: "text",
      required: true,
      onChange: (e) => setLastName(e.target.value),
    },
    {
      controlId: "birthday",
      value: birthday,
      placeholder: "Ημ/νία Γέννησης",
      type: "date",
      required: true,
      onChange: (e) => setBirthday(e.target.value),
    },
    {
      controlId: "phone",
      value: phone,
      placeholder: "Τηλ.",
      type: "tel",
      required: true,
      pattern: "^\\+\\d{1,3}\\d{10,14}$|^\\d{10}$",
      onChange: (e) => setPhone(e.target.value),
    },
    {
      controlId: "address",
      value: address,
      placeholder: "Διεύθυνση",
      type: "text",
      required: true,
      onChange: (e) => setAddress(e.target.value),
    },
    {
      controlId: "city",
      value: city,
      placeholder: "Πόλη",
      type: "text",
      required: true,
      onChange: (e) => setCity(e.target.value),
    },
    {
      controlId: "zip",
      value: zip,
      placeholder: "Τ.Κ.",
      type: "tel",
      required: true,
      pattern: "^\\d{5}(-\\d{4})?$",
      onChange: (e) => setZip(e.target.value),
    },
    {
      controlId: "email",
      value: email,
      placeholder: "email",
      type: "email",
      required: true,
      onChange: (e) => setEmail(e.target.value),
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = formRef.current;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      dispatch(setLoading(true));

      const data = {
        firstName,
        lastName,
        email,
        phone,
        address,
        zip,
        city,
        sex,
        birthday,
        salesChanel,
      };

      const response = await getFromJS("setTrdr", data);
      if (response.success) {
        dispatch(setTrdr({ ...data, gdpr: [], trdr: response.id }));
        navigate(`/customer/${response.id}`);
      } else {
        dispatch(setError(response.error));
      }
      dispatch(setLoading(false));
    }
    setValidated(true);
  };

  const handleExternalSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  return (
    <>
      <Card.Body className="overflow-auto">
        <Form
          className="customer-form"
          ref={formRef}
          autoComplete="off"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          {fields.map((item, i) => (
            <FloatingLabel
              key={i}
              controlId={item.controlId}
              label={
                <>
                  {item.placeholder} {item.required && item.value == "" && <span style={{ color: "red" }}>*</span>}
                </>
              }
              className="form-item mb-2"
            >
              <Form.Control
                {...item}
                controlId={undefined}
                autoComplete="off"
                // Only apply validation feedback to required fields
                isInvalid={item.required && validated && !item.value}
                isValid={item.required && validated && !item.value}
              />
            </FloatingLabel>
          ))}
          <FloatingLabel
            controlId="sex"
            label={<>Φύλο {sex === "" && <span style={{ color: "red" }}>*</span>}</>}
            className="form-item mb-2"
          >
            <Form.Select
              placeholder="sex"
              value={sex}
              required={true}
              onChange={(e) => setSex(e.target.value)}
              // Only apply validation feedback to required fields
              isInvalid={validated && !sex}
              isValid={validated && !sex}
            >
              <option value="">Επιλέξτε Φύλο</option>
              <option value="1">Αρρεν</option>
              <option value="2">Θήλυ</option>
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel
            controlId="salesChanel"
            label={<>Πώς μας βρήκατε {salesChanel === "" && <span style={{ color: "red" }}>*</span>}</>}
            className="form-item mb-2"
          >
            <Form.Select
              placeholder="sex"
              value={salesChanel}
              required={true}
              onChange={(e) => setSalesChanel(e.target.value)}
              // Only apply validation feedback to required fields
              isInvalid={validated && !salesChanel}
              isValid={validated && !salesChanel}
            >
              <option value="">Επιλέξτε</option>
              {salesChanels &&
                salesChanels.map((s) => (
                  <option key={s.code} value={s.code}>
                    {s.name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Form>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <Button onClick={() => navigate("/")} variant="seconodary">
          Πίσω
        </Button>
        <Button onClick={handleExternalSubmit} variant="success">
          Αποθήκευση
        </Button>
      </Card.Footer>
    </>
  );
};

export default NewCustomer;
