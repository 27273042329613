import { createSlice } from "@reduxjs/toolkit";
import { setSoftone } from "./services";
const initialState = JSON.parse(localStorage.getItem("salesChanels")) || {};

const salesChanelsSlice = createSlice({
  name: "salesChanels",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSoftone, (state, action) => {
      localStorage.setItem("salesChanels", JSON.stringify(action.payload.salesChanels));
      return action.payload.salesChanels;
    });
  },
});

export default salesChanelsSlice.reducer;
