import axios from "axios";

const login = async (data) => {
  return await axios
    .post(`${process.env.REACT_APP_API_ENDPOINT}/s1services`, {
      url: "",
      ...data,
      service: "login",
      appID: process.env.REACT_APP_S1_APPID,
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default login;
