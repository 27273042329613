import { createSlice } from "@reduxjs/toolkit";

const trdrSlice = createSlice({
  name: "trdr",
  initialState: null,
  reducers: {
    setTrdr: (state, action) => {
      return action.payload;
    },
    setGdpr: (state, action) => {
      state.gdpr = action.payload;
    },
  },
});

export default trdrSlice.reducer;
export const { setTrdr, setGdpr } = trdrSlice.actions;
