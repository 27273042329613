import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(localStorage.getItem("auth")) || null;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      localStorage.setItem("auth", JSON.stringify(action.payload));
      return { ...action.payload };
    },
    logout: (state) => {
      localStorage.removeItem("auth");
      return null;
    },
  },
});

export default authSlice.reducer;
export const { setAuth, logout } = authSlice.actions;
