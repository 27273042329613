import React from "react";
import { Card, FloatingLabel, Form } from "react-bootstrap";

const Type5 = ({ question, values, setValues }) => {
  const handleClick = (q, v) => {
    const sv = [...values];

    const existingObject = sv.find((item) => item?.code === q);

    if (existingObject) {
      existingObject.value = v;
    } else {
      sv.push({ code: q, value: v });
    }
    setValues(sv);
  };

  const handleRemarks = (q, v) => {
    const sv = [...values];

    const existingObject = sv.find((item) => item?.code === q);

    if (existingObject) {
      existingObject.remarks = v;
    } else {
      sv.push({ code: q, remarks: v });
    }
    setValues(sv);
  };
  return (
    <Card className="mb-4">
      <Card.Header>{question.name}</Card.Header>
      <Card.Body>
        {question.answers &&
          question.answers.length > 0 &&
          question.answers.map((answer) => (
            <div className="form-check d-flex gap-2 align-items-center" key={answer.code}>
              <input
                style={{ minHeight: "1.5rem", minWidth: "1.5rem" }}
                className="form-check-input"
                type="radio"
                id={`${question.code}_${answer.code}`}
                name={question.code}
                value={answer.code}
                required={question.isRequired}
                onChange={() => handleClick(question.code, answer.code)}
              />
              <label
                style={{ textAlign: "start" }}
                className="form-check-label"
                htmlFor={`${question.code}_${answer.code}`}
              >
                {answer.name}
              </label>
            </div>
          ))}
        {question.answers &&
          question.answers.length > 0 &&
          question.properties?.triggerValue &&
          question.properties?.label &&
          values.find((item) => item?.code == question.code)?.value == question.properties?.triggerValue && (
            <FloatingLabel controlId={question.code} label={question.properties?.label} className="mt-2">
              <Form.Control
                type="text"
                placeholder={question.properties?.label}
                autoComplete="off"
                required={question.isRequired}
                onBlur={(e) => handleRemarks(question.code, e.target.value)}
              />
            </FloatingLabel>
          )}
        {!question.answers && (
          <Form.Control
            type="text"
            autoComplete="off"
            required={question.isRequired}
            onBlur={(e) => handleRemarks(question.code, e.target.value)}
            className="mt-2"
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default Type5;
