import React from "react";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setError, setLoading, setPreview, setSignature } from "../redux/slices/ui";
import getFromJS from "../services/getFromJS";
import { TbSignature } from "react-icons/tb";
import printDoc from "../services/printDoc";
import ModalSignature from "../components/ModalSignature";
import { setGdpr } from "../redux/slices/trdr";
import { FaRegFilePdf } from "react-icons/fa";

const GDPR = () => {
  const dispatch = useDispatch();
  const trdrGdpr = useSelector((state) => state?.trdr?.gdpr);
  const { trdr } = useParams();
  const navigate = useNavigate();

  const gdpr = useSelector((state) => state?.gdpr);

  const selectedGdpr = trdrGdpr ? trdrGdpr.map((i) => i?.code) ?? [] : [];
  const [newGdpr, setNewGdpr] = React.useState([]);

  const handleSave = async (xdoc) => {
    dispatch(setLoading(true));
    const lines = newGdpr.map((i) => ({ code: i, xdoc }));
    const response = await getFromJS("setGdpr", {
      trdr,
      lines,
    });
    if (response.success) {
      dispatch(setGdpr([...(selectedGdpr ?? []), ...(newGdpr ?? [])].map((i) => ({ code: i })) ?? []));
      navigate(`/customer/${trdr}`);
    } else {
      dispatch(setError(response.error));
      console.log(response);
    }
    dispatch(setLoading(false));
  };

  const handleCheck = (value, mtrl) => {
    const sn = [...newGdpr];
    if (value) {
      sn.push(mtrl);
    } else {
      sn.splice(sn.indexOf(mtrl), 1);
    }
    setNewGdpr(sn);
  };

  const handlePrint = async (template) => {
    dispatch(setLoading(true));
    const response = await printDoc({ object: "CUSTOMER", template, key: trdr });
    if (response.success) {
      dispatch(setPreview({ show: true, url: response.filename }));
    } else {
      dispatch(setError(response.error));
    }

    dispatch(setLoading(false));
  };

  return (
    <>
      <Card.Body style={{ overflowY: "auto" }}>
        <ListGroup>
          {gdpr.map((item) => (
            <ListGroup.Item
              key={item.code}
              className={`d-flex flex-row justify-content-between align-items-center${
                selectedGdpr.indexOf(item.code) > -1 || newGdpr.indexOf(item.code) > -1 ? " selected" : ""
              }`}
            >
              <div className="text-truncate">{item.name}</div>
              <div className="d-flex align-items-center">
                {item?.template && (
                  <FaRegFilePdf size="2rem" className="me-4" onClick={() => handlePrint(item.template)} />
                )}

                <input
                  type="checkbox"
                  className="form-check-input"
                  style={{ minWidth: "2rem", minHeight: "2rem" }}
                  checked={selectedGdpr.indexOf(item.code) > -1 || newGdpr.indexOf(item.code) > -1}
                  readOnly
                  onChange={(e) => selectedGdpr.indexOf(item.code) == -1 && handleCheck(e.target.checked, item.code)}
                />
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-between">
        <Button onClick={() => navigate(`/customer/${trdr}`)} variant="secoendary">
          Πίσω
        </Button>
        <Button disabled={newGdpr.length == 0} onClick={() => dispatch(setSignature(true))} variant="success">
          <TbSignature size="1.5rem" className="me-2" />
          Υπογραφή
        </Button>
      </Card.Footer>
      <ModalSignature f={handleSave} />
    </>
  );
};

export default GDPR;
