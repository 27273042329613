import React from "react";
import { Card } from "react-bootstrap";

const SingleChoice = ({ question, values, setValues }) => {
  const handleClick = (q, v) => {
    const sv = [...values];

    const existingObject = sv.find((item) => item?.code === q);

    if (existingObject) {
      existingObject.value = v;
    } else {
      sv.push({ code: q, value: v });
    }
    setValues(sv);
  };

  return (
    <Card className="mb-4">
      <Card.Header>{question.name}</Card.Header>
      <Card.Body>
        {question.answers &&
          question.answers.length > 0 &&
          question.answers.map((answer) => (
            <div className="form-check d-flex gap-2 align-items-center" key={answer.code}>
              <input
                style={{ minHeight: "1.5rem", minWidth: "1.5rem" }}
                className="form-check-input"
                type="radio"
                id={`${question.code}_${answer.code}`}
                name={question.code}
                value={answer.code}
                required={question.isRequired}
                onChange={() => handleClick(question.code, answer.code)}
              />
              <label
                style={{ textAlign: "start" }}
                className="form-check-label"
                htmlFor={`${question.code}_${answer.code}`}
              >
                {answer.name}
              </label>
            </div>
          ))}
      </Card.Body>
    </Card>
  );
};

export default SingleChoice;
