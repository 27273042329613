// src/pages/HomePage.js
import React from "react";
import { Button, Card, FloatingLabel, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import getFromJS from "../services/getFromJS";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/slices/ui";
import { setSoftone } from "../redux/slices/services";
import ModalPass from "../components/ModalPass";
import { setTrdr } from "../redux/slices/trdr";

const HomePage = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [confirmPass, setcConfirmPassl] = React.useState(false);
  const [customer, setCustomer] = React.useState({});

  const initApp = async () => {
    const response = await getFromJS("initApp");
    if (response.success) dispatch(setSoftone(response));
  };

  React.useEffect(() => {
    // fetchServices();
    // fetchLaserQuestionnaire();
    initApp();
  }, []);

  React.useEffect(() => {
    if (confirmPass) navigate(`/customer/${customer.trdr}`);
  }, [confirmPass]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (value == "") {
      navigate("/customer/new");
    } else {
      dispatch(setLoading(true));
      const response = await getFromJS("getTrdr", { search: value });
      if (response.success) {
        dispatch(setTrdr(response));
        setCustomer(response);
        setShowModal(true);
        // navigate(`/customer/${response.trdr}`);
      } else {
        navigate("/customer/new");
      }

      dispatch(setLoading(false));
    }
  };
  return (
    <>
      <Card.Body className="overflow-auto">
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <FloatingLabel controlId="formName" label="Αναζήτηση">
            <Form.Control
              type="text"
              placeholder="Enter your name"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </FloatingLabel>
        </Form>
      </Card.Body>
      <Card.Footer>
        <Button onClick={handleSubmit} type="submit" variant="primary" className="w-100">
          Επόμενο
        </Button>
      </Card.Footer>
      <ModalPass show={showModal} setShow={setShowModal} setcConfirmPassl={setcConfirmPassl} />
    </>
  );
};

export default HomePage;
