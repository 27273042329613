import React from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import { FaPowerOff } from "react-icons/fa";
import { logout } from "../redux/slices/auth";
import { useNavigate } from "react-router-dom";
const CardForm = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.ui.loading);
  const login = useSelector((state) => state.login);
  const auth = useSelector((state) => state.auth);

  const handleLogout = () => {
    console.log("logout");
    dispatch(logout());
  };
  return (
    <>
      {loading && <Loader />}
      {auth && (
        <div className="logout-icon" onClick={handleLogout}>
          <FaPowerOff />
        </div>
      )}
      <Card className="text-center w-100 mx-4" style={{ maxWidth: "1000px", minWidth: "50%", maxHeight: "85%" }}>
        <Card.Header>
          <img src={"/logo-antiagers.svg"} alt="Logo" style={{ width: "90%", height: "60px" }} />
        </Card.Header>
        {children}
      </Card>
    </>
  );
};

export default CardForm;
