import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./auth";
const initialState = JSON.parse(localStorage.getItem("login")) || null;

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      localStorage.setItem("login", JSON.stringify(action.payload));
      return { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      localStorage.removeItem("login");
      return null;
    });
  },
});

export default loginSlice.reducer;
export const { setLogin } = loginSlice.actions;
