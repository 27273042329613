import { createSlice } from "@reduxjs/toolkit";
import { setSoftone } from "./services";
const initialState = JSON.parse(localStorage.getItem("gdpr")) || {};

const gdprSlice = createSlice({
  name: "gdpr",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSoftone, (state, action) => {
      localStorage.setItem("gdpr", JSON.stringify(action.payload.gdpr));
      return action.payload.gdpr;
    });
  },
});

export default gdprSlice.reducer;
