import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../redux/slices/ui";
import { BiError } from "react-icons/bi";

const ModalError = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.ui.error);
  return (
    <Modal show={error} onHide={() => dispatch(setError())} dialogClassName="pt-3">
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-danger)",
          borderRadius: "50%",
          color: "var(--bs-light)",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BiError size="2rem" className="m-1 p-1" />
      </span>
      <Modal.Body className="d-flex justify-content-center align-items-center mt-2 w-100">{error}</Modal.Body>
    </Modal>
  );
};

export default ModalError;
